/**
 * Created by Andrey on 5/26/16.
 */
LandingModule = function(){
    this._default_location = new google.maps.LatLng(51.045, -114.057222);
    this.events = [];
    this.markers = [];
    this.gMap = new google.maps.Map(document.getElementById('gmap'), {
        center: this._default_location,
        scrollwheel: false,
        zoom: 10
    });
    this.first_event = {
        name:'.event-name',
        date:'.event-date',
        players:'.event-players',
        address: '.event-address',
        img:'.event-image'
    };
    this.second_event = {
        name:'.second-event-name',
        date:'.second-event-date',
        players:'.second-event-players',
        address:'.second-event-address',
        img:'.second-event-image'
    };
    this.third_event = {
        name:'.third-event-name',
        date:'.third-event-date',
        players:'.third-event-players',
        address:'.third-event-address',
        img:'.third-event-image'
    };
    this.event_num = '';
    this.getEvents();
    this.autocomplete = new google.maps.places.Autocomplete((document.getElementById('autocomplete')),
        {types: ['geocode']});
};

/**
 * get user Location and mark in map
 * @param position
 */
LandingModule.prototype.showPosition = function(position) {
    var self = this;
    self.gMap.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
    self._default_location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    self.getEvents();
    new google.maps.Marker({
        position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
        map: self.gMap
    });
    self.gMap.setZoom(10);

    $.getJSON('https://maps.googleapis.com/maps/api/geocode/json?latlng='+position.coords.latitude+','+position.coords.longitude +' &sensor=true', function (data){

        if(data.status === 'OK' && data.results[0].formatted_address){
            var splited_adress = data.results[0].formatted_address.split(',');
            var user_adress = splited_adress[0]+", "+ splited_adress[1]+", "+splited_adress[2];
            $('.user-location-text').text(user_adress)
        }
    })
};

LandingModule.prototype.getCurrentPosition = function(){
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition.bind(this));
    }
};

LandingModule.prototype.getRandomArbitrary = function(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
};


LandingModule.prototype.parseDate = function(dataObject){
    return moment(dataObject.date + dataObject.time.value + dataObject.time.ampm,'MM/DD/YYYYhhmmA').format('LT') + " "
        + moment(dataObject.date + dataObject.time.value + dataObject.time.ampm,'MM/DD/YYYYhhmmA').format('dddd')+ ", "
        + moment(dataObject.date + dataObject.time.value + dataObject.time.ampm,'MM/DD/YYYYhhmmA').format('LL');
};

LandingModule.prototype.parsed_date = function(event){
    return {
        date:moment.utc(event.event_date*1000).format('MM/DD/YYYY'),
        time:{
            value:moment.utc(event.event_date*1000).format('hhmm/A').split('/')[0],
            ampm:moment.utc(event.event_date*1000).format('hhmm/A').split('/')[1]
        }
    }
};

LandingModule.prototype.splited_adress = function(event){
    var address,
        splited_adress;
    splited_adress= event.location_address.split(',');
    address = splited_adress[0];
    if(splited_adress[1]){
        address+=', '+ splited_adress[1]
    }
    return address
};

/**
 * Set events content on LP
 * @param event_class
 * @param event
 */
LandingModule.prototype.setEventDescription = function(event_class, event){
    var self = this,
        parsed_date = self.parsed_date(event),
        address;
    if(event.location_address){
        address = self.splited_adress(event)
    }

    $(event_class.img).attr('src', API_CONFIG.API_URL + event.main_image);
    $(event_class.name).text(event.name);
    $(event_class.date).text(self.parseDate(parsed_date));
    $(event_class.players).text(event.joined_players_amount);
    $(event_class.address).text(address);
};
/**
 * Get events which 50 miles close to user
 */
LandingModule.prototype.getLockalEvents = function(arr){
    var self = this,
        R = 6371; // km
    var i=0;
    for (i;i<arr.length;i++){
        var lat1 = self._default_location.lat();
        var lon1 = self._default_location.lng();
        var lat2 = arr[i].latlng.lat();
        var lon2 = arr[i].latlng.lng();

        var dLat = (lat2-lat1) * Math.PI / 180;
        var dLon = (lon2-lon1) * Math.PI / 180;
        var lat1 = lat1 * Math.PI / 180;
        var lat2 = lat2 * Math.PI / 180;

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        arr[i].distance = R * c;

    }
    return arr;
};
/**
 * Get events, and mark in map
 */
LandingModule.prototype.getEvents = function(){
    var self = this;
    $.get({
        url: API_CONFIG.API_URL + "jarvis/event",
        data: {
            first_result:0,
            max_results:20
        }
    },function( data ) {
        var _events = [];
        self.ev_address =[];
        self.ev_whith_address =[];
        self.ev_address_requests =[];
        self.infoWindows = [];
        $('.events-container').hide();
        $('.first-event').show();
        $('.event').show();
        $('.third-event').show();
        if (data._embedded.event.length){

            data._embedded.event.forEach(function(item){
                if(item._embedded[0].location_address && !item._embedded[0].canceled){
                    _events.push(item._embedded[0])
                }
            });

            _events.map(function(item) {
                self.ev_address_requests.push($.getJSON('https://maps.googleapis.com/maps/api/geocode/json?address=' + item.location_address + '&sensor=false', null, function (data){
                    if(data.results.length){
                        var p = data.results[0].geometry.location;
                        var latlng = new google.maps.LatLng(p.lat, p.lng);
                        item.latlng = latlng;
                        self.ev_whith_address.push(item)
                    }
                }));

            });

            $.when.apply($, self.ev_address_requests).then(function(){
                self.ev_whith_address = self.getLockalEvents(self.ev_whith_address);
                if(self.markers.length){
                    for (var i = 0; i < self.markers.length; i++) {
                        self.markers[i].setMap(null);
                    }
                    self.markers.length = 0;
                }
                self.events.length = 0;
                self.ev_whith_address.map(function(item) {
                    if(item.distance<50){
                        self.events.push(item);
                        var image = 'assets/img/pinblue.png';
                        var contentString = '<div style="font-family: Montserrat; font-size: 10px">'+
                            '<a style="color:black" href='+API_CONFIG.HOST+"app/event/"+item.id+'><div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" style="padding-left: 0">'+
                            '<img style="height: 70px;width: 70px;border-radius: 100%;margin-top: 10px;" class="event-image" src="'+API_CONFIG.API_URL+item.main_image+'">'+
                            '</div>'+
                            '<div class="event-info col-lg-9 col-md-9'+
                            'col-sm-9 col-xs-8">'+
                            '<div style="font-weight: 600">' + item.name+
                            '</div>'+
                            '<div class="event-date date">'+ self.parseDate(self.parsed_date(item))+' </div>'+
                            '<div class="event-address">'+item.location_name +'</div>'+
                            '<div class="event-address">'+self.splited_adress(item) +'</div>'+
                            '<div style="margin-top: 5px;" class="event-players-info">'+
                            '<img style="height: 13px" class="img-players" src="assets/img/team.png">'+
                            '<span class="event-players"> ' + item.joined_players_amount + ' </span>'+
                            '<span>'+
                            'players'+
                            '</span>'+
                            '</div>'+
                            '</div></a>'+
                            '</div>';

                        var infowindow = new google.maps.InfoWindow({
                            content: contentString
                        });
                        self.infoWindows.push(infowindow);
                        var marker = new google.maps.Marker({
                            position: item.latlng,
                            map: self.gMap,
                            icon: image
                        });
                        self.markers.push(marker);
                        marker.addListener('mouseover', function() {
                            self.infoWindows.map(function(win) {
                                win.close()
                            });
                            infowindow.open(self.gMap, marker);
                        });
                        marker.addListener('click', function() {
                            self.gMap.setZoom(13);
                            self.gMap.setCenter(marker.getPosition());
                        });
                    }

                });
                if(self.events.length){
                    console.log("self.events",self.events)
                    self.events.sort(function(a, b){
                            if(a.event_date < b.event_date) return -1;
                            else if(a.event_date > b.event_date) return 1;
                            return 0;
                        });
                    $('.events-container').show();
                }
                self.event_num = self.getRandomArbitrary(0,self.events.length);
                if(self.events[0]){
                    self.setEventDescription(self.first_event,self.events[0]);
                    self.first_event.id = self.events[0].id;

                }else{
                    $('.first-event').hide()
                }
                self.event_num = self.getRandomArbitrary(0,self.events.length);
                if(self.events[1]){
                    self.setEventDescription(self.second_event,self.events[1]);
                    self.second_event.id = self.events[1].id;
                }else{
                    $('.event').hide()
                }
                self.event_num = self.getRandomArbitrary(0,self.events.length);
                if(self.events[2]){
                    self.setEventDescription(self.third_event,self.events[2]);
                    self.third_event.id = self.events[2].id;
                }else{
                    $('.third-event').hide()
                }
            });
        }else{
            $('.events-container').hide()
        }
    });
};

LandingModule.prototype.fillInAddress = function(){
    // Get the place details from the autocomplete object.

    $('.user-location-text, .pinred').show();
    $('.autocomplete').hide();
    var place = this.autocomplete.getPlace();
    if(place.formatted_address){
        $('.user-location-text').text(place.formatted_address)
    }
    if (place.geometry) {
        this._default_location = place.geometry.location;
        this.gMap.panTo(place.geometry.location);
        this.gMap.setZoom(10);
        this.getEvents();
    }

};

LandingModule.prototype.initAutocomplete = function(){
    this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));
};

LandingModule.prototype.eventOnCLick = function(event_class)
{
    switch (event_class){
        case 'first-event':
            if(this.first_event.id)
                document.location.href = API_CONFIG.HOST+"app/event/" + this.first_event.id;
            break;
        case 'second-event':
            if(this.second_event.id)
                document.location.href = API_CONFIG.HOST+"app/event/" + this.second_event.id;
            break;
        case 'third-event':
            if(this.third_event.id)
                document.location.href = API_CONFIG.HOST+"app/event/" + this.third_event.id;
            break;
    }

};




