/**
 * Created by Andrey on 5/27/16.
 */
$(document).ready(function () {


    var myStorage = localStorage;

    if(myStorage.breakoutplay_access_token){
        document.location.href = API_CONFIG.HOST+'app/events/all'
    }

    var face_book = new FaceBookModule();
    var landing = new LandingModule();

    landing.getCurrentPosition();
    landing.initAutocomplete();

    $('.btn-text').click(function(){
        document.location.href = API_CONFIG.HOST+"app/signin";
    });

    $('.first-join-btn').click(function(){
        landing.eventOnCLick('first-event');
    });
    $('.second-join-btn').click(function(){
            landing.eventOnCLick('second-event');
        });
    $('.third-join-btn').click(function(){
            landing.eventOnCLick('third-event');
        });
    $('.fb-btn').click(function(){
        face_book.FbLogin();
    });

    $('.signup-btn').click(function(){
        $('#email').removeClass('error-input');
        var user_email = $('#email').val();
        function validateEmail($email) {
            var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
            return emailReg.test( $email );
        }
        if(validateEmail(user_email) && user_email != ''){
            document.location.href = API_CONFIG.HOST +'app/chooseteam/'+ $('#email').val();
        }else{
            $('#email').addClass('error-input');
        }

    });
    $( "#email" ).change(function() {

        var user_email = $('#email').val();
        function validateEmail($email) {
            var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
            return emailReg.test( $email );
        }
        if(validateEmail(user_email) && user_email != ''){
            $('#email').removeClass('error-input');
        }else{
            $('#email').addClass('error-input');
        }
    });
    $('.signup, .started-text,.join-text,.one-btn').click(function(){
         document.location.href = API_CONFIG.HOST+"app/chooseteam/";
    });
    $('.location-btn').click(function(){
        $('.user-location-text, .pinred').hide();
        $('.autocomplete').val('').show();
    })


});